import { defineAsyncComponent } from 'vue';
import { mountApp, app } from './global';

const TopCourses = defineAsyncComponent(() => import('../../home/TopCourses.vue'));
const ActivityCenter = defineAsyncComponent(() => import('../../home/ActivityCenter.vue'));
const PrizePoolModal = defineAsyncComponent(() => import('../../home/PrizePoolModal.vue'));
const MediaPartnersModal = defineAsyncComponent(() => import('../../home/MediaPartnersModal.vue'));

app.component('TopCourses', TopCourses);
app.component('ActivityCenter', ActivityCenter);
app.component('PrizePoolModal', PrizePoolModal);
app.component('MediaPartnersModal', MediaPartnersModal);

mountApp();
